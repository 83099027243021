<template>
  <div class="searchWrapper" @keydown.enter.prevent="">
    <!-- <el-button class="right"
               style="width: 88px; height: 36px; margin-top: 0px;"
               @click="$router.go(-1)">返回</el-button> -->
    <div class="content">
      <el-form
        :inline="true"
        status-icon
        label-position="right"
        label-width="80px"
        :model="formInline"
        ref="formInline"
        class="demo-form-inline"
      >
        <el-form-item :label="$t('searchModule.region')" prop="areaId">
          <a-cascader ref="cascader" @change="parkClear"></a-cascader>
        </el-form-item>
        <!-- <el-form-item :label="$t('searchModule.Belonging_operator')">
          <a-operation-select ref="operationSelect" @change="parkClear"></a-operation-select>
        </el-form-item> -->
        <el-form-item :label="$t('searchModule.Road_or_Parking_Lot')">
          <a-park-select
            ref="parkSelect"
            :instance="this"
            areaRefName="cascader"
          ></a-park-select>
        </el-form-item>
        <!-- <el-form-item :label="$t('searchModule.region')">
          <el-select v-model.trim="formInline.areaId"
                     @change='areaChange'
                     filterable
                     size="15"
                     placeholder="请选择"
                     style="width: 150px;">
            <el-option label="全部"
                       value=""></el-option>
            <el-option :label="area.areaName"
                       :value="area.areaId"
                       :key="area.areaId"
                       v-for="area in areaList"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('searchModule.region')">
          <el-select v-model.trim="formInline.streetId"
                     filterable
                     size="15"
                     placeholder="请选择"
                     style="width: 150px;">
            <el-option label="全部"
                       value=""></el-option>
            <el-option :label="area.areaName"
                       :value="area.areaId"
                       :key="area.areaId"
                       v-for="area in areaList2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('searchModule.park_name')">
          <my-component ref="parkInput"
                        :areaIds='formInline.streetId ? formInline.streetId : formInline.areaId'
                        @valueChange='completeValue'
                        slaveRelations='0,1'>
          </my-component>
        </el-form-item> -->
        <el-form-item :label="$t('searchModule.charge_type')">
          <el-select v-model.trim="formInline.scopeId" filterable size="15">
            <el-option label="全部" value=""></el-option>
            <el-option label="一类区" value="1"></el-option>
            <el-option label="二类区" value="2"></el-option>
            <el-option label="三类区" value="3"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item :label="$t('searchModule.Vehicle_type')" label-width="80px">
          <el-select filterable v-model.trim="formInline.plateNumberType">
            <el-option label="全部" value="0"></el-option>
            <el-option label="不含无牌车" value="1"></el-option>
            <el-option label="无牌车" value="2"></el-option>
          </el-select>
        </el-form-item> -->
        <span style="padding-bottom: 20px;">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="
              pageNum = 1;
              searchData();
            "
            :loading="loading"
            >{{ $t('button.search') }}</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-tickets"
            @click="exportExcelReport()"
            :loading="loading"
            >{{ $t('button.export') }}</el-button
          >
        </span>
        <!-- v-if='authority.button.export' -->
      </el-form>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table  v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
      </div>
    </div>
    <!--分页器-->
    <div class="pagerWrapper">
      <div class="block">
        <el-pagination
          v-if="total != 0"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { exportExcelNew } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";
export default {
  name: "",
  data() {
    return {
      // authority: this.$setAuthority('/arrearageParkingList'),
      // 区域列表
      areaList: [],
      // 区域街道
      areaList2: [],
      // 计费类型
      billingTypes: [],
      loading: false,
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 15,
      formInline: {
        areaId: "",
        parkId: "",
        streetId: "",
        scopeId: "",
        parkName: "",
      },
      tableCols: [
        {
          prop: "parkName",
          label: "道路/车场",
          width: "",
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "",
        },
        // {
        //   prop: "streetName",
        //   label: this.$t("list.region"),
        //   width: "",
        // },
        {
          prop: "shouldPay",
          label: "累计应收金额(元)",
          width: "",
          formatter: (row, column) => {
            if (row.shouldPay) {
              return Number(row.shouldPay / 100)
                .toFixed(2)
                .toString()
                .replace(/(\d)(?=(?:\d{3})+$)/g, ",");
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "actualPay",
          label: "累计实收金额(元)",
          width: "",
          formatter: (row, column) => {
            if (row.actualPay) {
				  let price = Number(row.actualPay / 100).toFixed(2);
				  price = price.split(".")[0].replace(/(?=(\B)(\d{3})+$)/g, ",") + "." + price.split(".")[1]
//              return Number(row.totalActualPay / 100).toFixed(2).toString().replace(/(\d)(?=(?:\d{3})+$)/g, ",");
              return price
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "debtMoney",
          label: "现存欠费金额(元)",
          width: "",
          formatter: (row, column) => {
            if (row.debtMoney) {
				  let price = Number(row.debtMoney / 100).toFixed(2);
				  price = price.split(".")[0].replace(/(?=(\B)(\d{3})+$)/g, ",") + "." + price.split(".")[1]
//              return Number(row.totalArrearageAmount / 100).toFixed(2).toString().replace(/(\d)(?=(?:\d{3})+$)/g, ",");
				  return price
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "debtNum",
          label: "现存欠费订单(个)",
          width: "",
        },
        {
          prop: "ratio",
          label: "欠费金额占比",
          width: "",
          formatter: (row, column) => {
            if (row.ratio > 0 && row.ratio < 100) {
              return row.ratio.toFixed(2) + "%";
            } else if (row.ratio >= 100) {
              return '100%'
            } else {
              return "0.00%";
            }
          },
        },
      ],
    };
  },
  methods: {
    parkClear() {
      this.$refs.parkSelect.clear();
    },
    areaChange(item) {
      if (!item) {
        this.formInline.streetId = "";
        this.areaList2 = [];
        return;
      }
      this.getChildrenArea(item);
    },
    // 初始化用户区域下拉列表（区域和街道）
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        if (res.value.depth == 3) {
          this.areaList = res.value.areaList;
          this.formInline.areaId = "";
        } else if (res.value.depth == 4) {
          this.areaList2 = res.value.areaList;
          this.formInline.areaId = "";
        }
      });
    },
    getChildrenArea(item) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: item,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
          this.formInline.streetId = "";
        });
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    // 计费类型
    billingFn() {
      this.$axios.get("/acb/2.0/systems/loginUser/initScopeList").then((res) => {
        this.billingTypes = res.value.scopeList;
      });
    },
    // 查询
    searchData() {
      // /acb/2.0/debtStatistics/arrearageparklist 老接口
      this.$axios
        .get("/acb/2.0/aceDebtStatistics/parkDebtAnalysis", {
          data: {
            // operationId: this.$refs.operationSelect
            //   ? this.$refs.operationSelect.getOperationId()
            //   : "",
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
            scopeId: this.formInline.scopeId,
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            // plateNumberType: this.formInline.plateNumberType
          },
        })
        .then((res) => {
          this.tableData = res.value.list;
          this.total = res.value.total * 1 || 0;
        });
    },
    // 导出
    exportExcelReport() {
      let data = {
        // operationId: this.$refs.operationSelect ? this.$refs.operationSelect.getOperationId() : "",
        areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
        parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
        scopeId: this.formInline.scopeId,
        // plateNumberType: this.formInline.plateNumberType
      };
      // /acb/2.0/debtStatistics/arrearageparklistExport 老接口
      exportExcelNew("/acb/2.0/aceDebtStatistics/parkDebtAnalysisExport", data, "post");
    },
    // 分页
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
  },
  created() {
    this.getArea();
    // this.billingFn();
  },
  mounted() {
    this.searchData();
  },
  components: {},
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.searchWrapper
  // margin-top 35px
  .content
    background: #fff
    .el-form
      padding-top: 20px
  .pagerWrapper
    background: #fff;
    padding: 20px;
    margin-top: 0;
</style>
